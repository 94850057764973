.materialform {
  input,
  textarea {
    border: 1px solid #888;
    padding: 0.2em 0.2em;
    background: #fff;
    .ol-touch & {
      line-height: $touchfontsize;
    }
    &:disabled {
      background: #eee;
      color: #888;
    }
  }

  button {
    padding: 0.2em 0.3em;
    border-radius: $buttonradius;
    line-height: 1.4em;
    border-width: 1px;
    border-style: solid;
    border-color: #888;
    background-color: #fff;
    transition: background 0.5s ease;
    font-weight: normal;
    color: #000;
    cursor: pointer;
    .ol-touch & {
      border-radius: $touchbuttonradius;
      padding: 0.3em 0.4em;
    }
    &.active {
      background-color: #dfd;
    }
    &.pending {
      background-color: #ffd;
    }
    &.error {
      background-color: #fdd;
    }
  }

  svg.inline-icon {
    height: 1.4em;
    width: 1.4em;
    padding-top: 0em;
    padding-right: 0.2em;
    display: block;
    float: left;
  }

  %checkbutton {
    color: #000;
    margin: 0.1em;
    background-color: #fff;
    border-style: solid;
    border-width: 1px;
    border-left: 1em solid;
  }
  .checkbutton-on {
    @extend %checkbutton;
    opacity: 1;
    border-color: #0c0;
  }
  .checkbutton-off {
    @extend %checkbutton;
    opacity: 0.5;
    text-decoration: line-through #aaa;
    border-color: #aaa;
  }

  .dropdown {
    position: relative;
    div {
      padding: 0.2em 0em;
      height: 1.5em;
      .ol-touch & {
        line-height: $touchfontsize;
      }
      svg.dropdownarrow {
        float: right;
      }
    }
    .dropdown-content {
      display: none;
      position: absolute;
      padding: 0;
      top: 1.9em;
      background: #fff;
      border: #888 1px solid;
      height: auto;
      width: 100%;
      div {
        display: block;
        width: calc(100% - 0.4em);
        padding: 0.2em;
        &:hover {
          background-color: #eee;
        }
        .ol-touch & {
          line-height: $touchfontsize;
          padding: 0.4em 0.2em;
        }
      }
      z-index: 1;
    }
    &:focus .dropdown-content {
      display: block;
    }
  }

  .settings {
    div:not(:first-child) {
      margin-top: 0.5em;
    }

    label.heading {
      display: block;
    }

    input[type="text"],
    input[type="password"] {
      width: 100%;
      max-width: 15em;
    }

    textarea {
      width: 100%;
      height: 18em;
    }

    button {
      margin-top: 0.5em;
      &.default {
        @extend %defaultbutton;
      }
    }
  }
}
