@import "globals";
#loginscreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $dielinkerot;
  background: linear-gradient($dielinkerot, $darkred);
  display: block;
  z-index: 2;
  transition: opacity 0.5s ease;
  .logo {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -11em;
    height: 6em;
    background: #fff;
    .logo-plakate {
      position: relative;
      height: auto;
      width: 16em;
      max-width: 100%;
      margin: 1em;
      left: 50%;
      margin-left: -8em;
    }
    .logo-linkscloud {
      position: fixed;
      top: 50%;
      left: 50%;
      margin-top: -15.6em;
      margin-left: -3em;
      width: 13em;
    }
  }
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

#logincredentials {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -2em;
  margin-left: -8em;
  width: 16em;
}

#logincredentials * {
  border: none;
  margin: 0;
  width: 100%;
}

#logincredentials input {
  padding: 0.75em;
}

#logincredentials button {
  padding: 0.75em;
  background: $dielinkerot;
  color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#login-message {
  margin-top: 1em;
  background: #ffd;
  color: #884;
  padding: 0.75em;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
  border-radius: 10px;
  transition: opacity 0.5s ease;
  pointer-events: none;
  &.hidden {
    opacity: 0;
  }
}

#username {
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#password {
  border-radius: 0;
}

#login {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

#version {
  position: fixed;
  bottom: 1em;
  right: 1em;
  color: #fff;
}
