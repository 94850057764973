$dielinkerot: #df0404;
$darkerred: #e00;
$darkred: #800;
$buttonradius: 1em;
$touchfontsize: 1.5em;
$touchbuttonlineheight: 1.5em;
$touchbuttonradius: 1.5em;
$softblue: #44d;
%defaultbutton {
  background-color: $softblue;
  border-color: $softblue;
  color: white;
}
