@import "globals";
$drawerwidth: 35em;
#subpage {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: $drawerwidth;
  transition: all 300ms;
  overflow: auto;
  pointer-events: all;
  z-index: 5;
  padding: 1em;
  &.hidden {
    left: 100%;
    opacity: 0;
    #subpage-drawer {
      #subpage-header {
        left: 100%;
      }
      #subpage-content {
        left: 100%;
      }
    }
    #subpage-background {
      left: 100%;
    }
  }
  #subpage-drawer {
    z-index: 1;
    width: 100%;
    max-width: $drawerwidth;
    #subpage-header {
      pointer-events: all;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 2em);
      padding: 0.5em 1em;
      background: $dielinkerot;
      color: #fff;
      transition: all 300ms;
      z-index: 1;
      svg {
        width: 1em;
        height: 1em;
        float: left;
        margin-top: 0.2em;
        margin-right: 1em;
        cursor: pointer;
      }
      .ol-touch & {
        line-height: 2.2em;
        svg {
          width: 1.8em;
          height: 1.8em;
        }
      }
    }
    #subpage-content {
      position: fixed;
      top: 2.4em;
      left: 0;
      bottom: 0;
      width: calc(100% - 2em);
      background: #fff;
      max-width: $drawerwidth;
      padding: 1em 1em 0em 1em;
      transition: all 300ms;
      overflow: auto;
      .ol-touch & {
        top: 3em;
      }
      img {
        max-width: 100%;
      }
      p {
        margin: 0;
      }
      h1,
      h2,
      h3 {
        margin: 0.5em 0em 0.2em 0;
        font-weight: normal;
      }

      .list {
        margin: 0 -1em 0 -1em;
        .list-element {
          width: calc(100% - 3.5em);
          padding: 0.5em 1em 0.5em 2.5em;
          border-bottom: 1px solid #eee;
          cursor: pointer;
          &.disabled {
            cursor: unset;
            opacity: 0.5;
            pointer-events: none;
          }
          &.hidden {
            display: none;
          }
          &:not(.disabled):hover {
            background-color: #eee;
          }
          .list-icon {
            float: left;
            margin: 0.2em -1.5em;
            width: 1em;
            height: 1em;
          }
          .list-title {
          }
          .list-description {
            padding-top: 0.1em;
            font-size: 80%;
          }
        }
      }
    }
  }
  #subpage-background {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: -$drawerwidth;
    background-color: rgba($color: #000000, $alpha: 0.5);
    pointer-events: all;
    transition: all 300ms;
  }
}
