@import "globals";
$drawerwidth: 16em;
#menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 500ms;
  z-index: 10;
  pointer-events: none;
  overflow: hidden;
  #menu-drawer {
    position: relative;
    top: 0;
    bottom: 0;
    height: 100%;
    padding: 0;
    max-width: calc(100% - 2em);
    background: #fff;
    pointer-events: all;
    width: $drawerwidth;
    left: 0;
    transition: all ease 500ms;
    overflow: hidden;
    .menu-header {
      background: $dielinkerot;
      background: linear-gradient($dielinkerot, $darkred);
      padding: 4em 0em 1em 0em;
      .logo {
        position: relative;
        padding: 0.5em 3em;
        background: #fff;
        .logo-plakate {
          width: 100%;
        }
        .logo-linkscloud {
          position: absolute;
          width: 50%;
          top: -3em;
          right: 2.5em;
        }
      }
      #menu-username {
        display: none;
      }
    }
    .menu-content {
      span {
        display: block;
        padding: 0.5em 1em;
        cursor: pointer;
        .ol-touch & {
          line-height: 1.75em;
          svg {
            padding-top: 0.15em;
          }
        }
        &:hover {
          background-color: #eee;
        }
        &.hidden {
          display: none;
        }
        svg {
          float: left;
          margin: 0.2em 0.4em 0em -0.1em;
          width: 1em;
          height: 1em;
        }
      }
    }
    #menu-content-top {
      position: relative;
      padding-top: 0.5em;
      left: 0;
      right: 0;
    }
    #menu-content-bottom {
      position: absolute;
      bottom: 0.5em;
      left: 0;
      right: 0;
    }
  }
  #menu-background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: -$drawerwidth;
    background-color: rgba($color: #000000, $alpha: 0.5);
    pointer-events: all;
    transition: all 500ms;
  }
  &.hidden {
    #menu-background {
      pointer-events: none;
      background: none;
      transform: translate(-$drawerwidth, 0);
    }
    #menu-drawer {
      transform: translate(-$drawerwidth, 0);
    }
  }
}
