@import "globals";
@import "forms";
@import "overlays";
@import "login";
@import "popup";
@import "iconbutton";
@import "menu";
@import "subpage";
@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto-Regular.ttf") format("truetype");
}

html,
body {
  background: #eee;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-family: "Roboto", "Helvetica Neue", Helvetica, "Segoe UI", Arial,
    sans-serif;
  color: #000;
  padding: 0;
  margin: 0;
}

body {
  line-height: 1.42857143;
}

img {
  transition: all 0.2s linear;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
}

button {
  border-width: 1px;
  border-color: #000;
}

table {
  width: 100%;
}

td {
  vertical-align: baseline;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

label {
  font-weight: normal;
}

.line-trough {
  text-decoration: line-through;
}

.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $dielinkerot;
  background: linear-gradient($dielinkerot, $darkred);
  transition: opacity linear 1s;
  &.hidden {
    opacity: 0;
  }
}

#map {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  transition: opacity linear 1s;
  &.hidden {
    opacity: 0;
  }
}

#center {
  pointer-events: none;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  &.hidden {
    display: none;
  }
}

.ol-dragbox {
  background-color: rgba(255, 255, 255, 0.4);
  border-color: rgba(100, 150, 0, 1);
}
