@import "globals";
%iconbutton {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  border: 1px solid #888;
  &:hover {
    border-color: #aaa;
  }
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  .ol-touch & {
    font-size: $touchfontsize;
    button {
      font-size: 1em;
    }
    .buttonhelp {
      font-size: 0.75em;
      padding: 0 0.5em;
      margin: 0.3em 0.6em;
    }
  }
  svg {
    height: calc(100% - 0.6em);
    width: calc(100% - 0.6em);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    padding: 0.3em;
  }
  button {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 50%;
    padding: 1em;
    border: none;
    background: none;
    z-index: 1;
  }
  &.ol-hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s linear, visibility 0s linear 0.25s;
  }
  transition: opacity 0.25s linear, visibility 0s linear;
}

.iconbutton-normal {
  @extend %iconbutton;
  svg {
    background-color: #fff;
  }
}

.iconbutton-active {
  @extend %iconbutton;
  svg {
    background-color: #dfd;
  }
}

.iconbutton-pending {
  @extend %iconbutton;
  svg {
    background-color: #ffd;
  }
}

.iconbutton-error {
  @extend %iconbutton;
  svg {
    background-color: #fdd;
  }
}

.buttonhelp {
  padding: 0 0.5em;
  background: #dfd;
  color: #000;
  border-radius: 1em;
  display: inline-block;
  line-height: 2em;
  white-space: nowrap;
  position: absolute;
  transition: all 0.5s linear;
  pointer-events: none;
  opacity: 0.9;
  &.hidden {
    opacity: 0;
  }
}

.buttonhelp-left:before {
  content: "← ";
}

.buttonhelp-right:after {
  content: " →";
}
