#message-bar {
  position: fixed;
  left: 0.2em;
  bottom: 0.2em;
  margin-right: 4.5em;
  border-radius: 0.5em;
  padding: 0.5em;
  z-index: 8;
  display: block;
  width: auto;
  transition: opacity 0.5s ease;
  pointer-events: none;
  &.hidden {
    opacity: 0;
    #message-close {
      pointer-events: none;
    }
  }
  #message {
    position: relative;
    padding-right: 1.5em;
  }
  #message-close {
    position: absolute;
    top: 0.05em;
    right: 0.45em;
    padding: 0.2em 0.2em;
    margin-left: 2em;
    cursor: pointer;
    pointer-events: all;
  }
  &.info {
    background: #dfd;
    color: #000;
  }
  &.warning {
    color: #fff;
    background: #f90;
    #message:before {
      content: "⚠ ";
    }
  }
}

#help-bar {
  position: fixed;
  top: 0.2em;
  left: 4.5em;
  right: 0.2em;
  text-align: center;
  pointer-events: none;
  opacity: 0.9;
  z-index: 12;

  #help {
    position: relative;
    display: inline-block;
    text-align: left;
    background: #dfd;
    color: #000;
    border: 1px solid #888;
    border-radius: 1em;
    padding: 0.25em 0.5em;
    z-index: 1;
    padding-right: 1.5em;
    min-height: 1em;
    transition: opacity 0.5s ease;
    #help-message {
      padding-right: 0.25em;
    }
    #help-close {
      position: absolute;
      top: 0.05em;
      right: 0.45em;
      padding: 0.2em 0.2em;
      margin-left: 2em;
      cursor: pointer;
      pointer-events: all;
    }
    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}

#spinner {
  position: absolute;
  bottom: 2em;
  left: 2em;
  opacity: 0.9;
  transition: opacity 0.5s ease;
  z-index: 3;
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2em;
    height: 2em;
    margin-top: -1em;
    margin-left: -1em;
    border-radius: 50%;
    border-top: 0.2em solid $darkerred;
    border-right: 0.2em solid transparent;
    animation: spinner 0.6s linear infinite;
    pointer-events: none;
  }
  &.hidden {
    opacity: 0;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
