@import "globals";
#popup {
  position: fixed;
  left: 50%;
  bottom: 0.2em;
  transform: translate(-50%, 0);
  background-color: #fff;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  border-radius: 1em;
  border: 1px solid #ccc;
  z-index: 5;
  width: calc(100% - 0.5em);
  max-width: 32em;
  max-height: calc(100% - 0.5em);
  overflow: auto;
  transition: all 100ms ease;
  &.hidden {
    transform: translate(-50%, calc(100% + 0.2em));
  }
  .popup-buttons {
    text-align: right;
    background: #eee;
    position: relative;
    padding: 0.3em 0.8em 0.5em 0.6em;
    width: calc(100% - 1.4em);
    margin-top: 1em;
    button {
      line-height: 1.4em;
      margin-left: 0.2em;
      margin-top: 0.2em;
      background-color: #fff;
      border-color: #888;
      svg.inline-icon {
        padding-right: 0;
      }
    }
    .popup-button-default {
      @extend %defaultbutton;
    }
    .popup-button-warning {
      border-color: #e00;
    }
  }
  .popup-table {
    padding-top: 0.8em;
    width: calc(100% - 1.6em);
    left: 0.8em;
    position: relative;
    .popup-leftcolumn {
      padding-right: 0.2em;
      width: 1px;
      white-space: nowrap;
      vertical-align: top;
    }
    .popup-rightcolumn {
      width: 100%;
    }
  }
  #popup-poster {
    input,
    textarea {
      width: 100%;
    }
    #popup-poster-owner textarea {
      height: 3.5em;
      &:invalid {
        border-color: #f00;
        background-color: #fee;
      }
    }
    #popup-poster-comment textarea {
      height: 4.5em;
    }
  }
  #popup-filter {
    #popup-filter-ownermode {
      display: none;
    }
  }
}
